<template>
  <out-layout>
    <header slot="form-header" class="header">
      <h2 class="heading mb-3">Alterar Senha</h2>
      <p class="grey--text mb-5">
        Digite sua nova senha abaixo e clique em confirmar.
      </p>
    </header>

    <template slot="form-content">
      <UpdatePasswordForm
        v-on:submit="sendNewPassword"
        :errorMessage="updateErrorMessage"
        :syncing="syncing"
      />

      <v-dialog
        v-model="updateSuccess"
        persistent
        width="460"
      >
        <v-card class="update-success">
          <v-card-text>
            <img class="mb-3 icon" src="@/assets/icon-success.svg" alt="Email enviado" />

            <h1 class="mb-3 title">Sua senha foi atualizada</h1>

            <p class="grey--text">
              Clique no botão abaixo para iniciar sua sessão com seus novos dados.
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :to="'../login'"
              flat
            >
              <v-icon left>mdi-account-circle</v-icon>
              Voltar o login
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="updateError"
        color="red"
        multi-line
      >
        {{ updateErrorMessage }}
        <v-btn
          flat
          @click="updateError = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
    </template>

    <template slot="navigation">
      <v-btn
        color="primary"
        :to="'../login'"
        flat
        small
      >
        <v-icon left>mdi-account-circle</v-icon>
        Voltar o login
      </v-btn>
    </template>
  </out-layout>
</template>

<script>
import OutLayout from '@/layouts/OutLayout.vue';
import UpdatePasswordForm from '@/components/auth/UpdatePasswordForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'UpdatePassword',
  components: {
    OutLayout,
    UpdatePasswordForm,
  },
  data() {
    return {
      syncing: false,
      updateSuccess: false,
      updateError: false,
      updateErrorMessage: '',
    };
  },
  methods: {
    ...mapActions({
      updatePassword: 'auth/updatePassword',
    }),
    sendNewPassword(password) {
      const token = this.$route.query.reset_password_token;

      this.syncing = true;
      this.updateErrorMessage = '';
      this.updatePassword({ password, token })
        .then(() => {
          this.updateSuccess = true;
        })
        .catch(({response}) => {
          this.updateError = true;

          if (response.status === 401) {
            this.updateErrorMessage = 'O tempo para alterar senha expirou. Solicitar novamente a recuperação de senha.';
          } else {
            this.updateErrorMessage = 'Aconteceu algo de errado! Tente novamente mais tarde.';
          }
        })
        .finally(() => {
          this.syncing = false;
        });
    },
  },
  props: ['token'],
};
</script>

<style lang="scss">
.update-success {
  & {
    padding: 1rem 2rem;
  }
  .icon {
    display: block;
    margin: auto;
    max-width: 64px;
  }
}
</style>
