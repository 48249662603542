var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      staticClass: "px-4",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        attrs: {
          label: "Nova Senha",
          type: _vm.hidePassword ? "password" : "text",
          "append-icon": _vm.hidePassword ? "mdi-eye" : "mdi-eye-off",
          rules: _vm.passwordRules,
          disabled: _vm.syncing,
          required: "",
        },
        on: {
          keypress: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.submit.apply(null, arguments)
          },
          "click:append": () => (_vm.hidePassword = !_vm.hidePassword),
        },
        model: {
          value: _vm.newPassword,
          callback: function ($$v) {
            _vm.newPassword = $$v
          },
          expression: "newPassword",
        },
      }),
      _c(
        "v-layout",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                disabled: !_vm.valid && !_vm.errorMessage,
                loading: _vm.syncing,
              },
              on: { click: _vm.submit },
            },
            [_vm._v("\n      Confirmar\n    ")]
          ),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }