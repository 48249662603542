var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "out-layout",
    [
      _c(
        "header",
        {
          staticClass: "header",
          attrs: { slot: "form-header" },
          slot: "form-header",
        },
        [
          _c("h2", { staticClass: "heading mb-3" }, [_vm._v("Alterar Senha")]),
          _c("p", { staticClass: "grey--text mb-5" }, [
            _vm._v(
              "\n      Digite sua nova senha abaixo e clique em confirmar.\n    "
            ),
          ]),
        ]
      ),
      _c(
        "template",
        { slot: "form-content" },
        [
          _c("UpdatePasswordForm", {
            attrs: {
              errorMessage: _vm.updateErrorMessage,
              syncing: _vm.syncing,
            },
            on: { submit: _vm.sendNewPassword },
          }),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", width: "460" },
              model: {
                value: _vm.updateSuccess,
                callback: function ($$v) {
                  _vm.updateSuccess = $$v
                },
                expression: "updateSuccess",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "update-success" },
                [
                  _c("v-card-text", [
                    _c("img", {
                      staticClass: "mb-3 icon",
                      attrs: {
                        src: require("@/assets/icon-success.svg"),
                        alt: "Email enviado",
                      },
                    }),
                    _c("h1", { staticClass: "mb-3 title" }, [
                      _vm._v("Sua senha foi atualizada"),
                    ]),
                    _c("p", { staticClass: "grey--text" }, [
                      _vm._v(
                        "\n            Clique no botão abaixo para iniciar sua sessão com seus novos dados.\n          "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", to: "../login", flat: "" },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-account-circle"),
                          ]),
                          _vm._v("\n            Voltar o login\n          "),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: "red", "multi-line": "" },
              model: {
                value: _vm.updateError,
                callback: function ($$v) {
                  _vm.updateError = $$v
                },
                expression: "updateError",
              },
            },
            [
              _vm._v("\n      " + _vm._s(_vm.updateErrorMessage) + "\n      "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  on: {
                    click: function ($event) {
                      _vm.updateError = false
                    },
                  },
                },
                [_vm._v("\n        Fechar\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "navigation" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary", to: "../login", flat: "", small: "" },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-account-circle"),
              ]),
              _vm._v("\n      Voltar o login\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }