<template>
  <v-form class="px-4" @submit.prevent="" lazy-validation v-model="valid">
    <v-text-field
      v-model="newPassword"
      label="Nova Senha"
      :type="hidePassword ? 'password' : 'text'"
      :append-icon="hidePassword ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="passwordRules"
      :disabled="syncing"
      @keypress.enter="submit"
      @click:append="() => (hidePassword = !hidePassword)"
      required
    />

    <v-layout>
      <v-spacer />
      <v-btn
        color="success"
        :disabled="!valid && !errorMessage"
        :loading="syncing"
        @click="submit"
      >
        Confirmar
      </v-btn>
      <v-spacer />
    </v-layout>
  </v-form>
</template>

<script>
import validationRules from '@/mixins/validation-rules';

export default {
  name: 'UpdatePasswordForm',
  props: ['syncing', 'errorMessage'],
  data() {
    return {
      valid: false,
      hidePassword: true,
      newPassword: '',
      passwordRules: [validationRules.required, validationRules.passwordMinLength.email],
    };
  },
  methods: {
    submit() {
      this.$emit('submit', this.newPassword);
    },
  },
};
</script>

<style lang="scss">

</style>
